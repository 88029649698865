import products from '../../../sharedTypes/products';
import constants from '../../constants';

const { states } = constants;

export default {
  product: products.PAYMENTS,
  state: states.PAYMENTS_ERROR,
  frozenConfig: {
    widget: {
      errorType: 'any type?',
      entityIdSelected: 'cl_banco_santander',
    },
    payments: {
      errorReason: 'insufficient_funds',
      accountNumber: 12123312,
      accountBalance: {
        limit: 1023456,
        available: 23456,
      },
      accountName: 'cuenta corriente',
      accountOfficialName: 'cuenta corriente oficial',
    },
    links: {
      accounts: [
        {
          id: 'id 1',
          number: 12123312,
          name: 'cuenta corriente',
          type: 'checking_account',
          currency: 'CLP',
          balance: {
            limit: 1023456,
            available: 23456,
          },
        },
        {
          id: 'id 2',
          number: 12121619,
          name: 'cuenta vista',
          type: 'sight_account',
          currency: 'CLP',
          balance: {
            limit: 4132971,
            available: 4132971,
          },
        },
      ],
    },
  },
};
